import { useSelector } from "react-redux";
import { createContext, useState } from "react";
import {
    // StaticMetric,
    StaticValues,
    StaticMetricView,
} from "../../../components/report_fragments/StaticMetrics";
import OutstandingWIP from "../../../components/report_fragments/OutstandingWIP";

import { db } from "../../../utils/DataRefresher";
import { useLiveQuery } from "dexie-react-hooks";
import { DataViewTable } from "../../../components/visualizations/Tables";
import { DoubleWIP } from "../../../components/report_fragments/WIPCharts";
import GridLoader from "react-spinners/GridLoader";

export const StaticWIPContext = createContext(null);

function WIPfragment(filter, titleHead, type) {
    const [staticWIP, setStaticWIP] = useState([]);
    const staticData = StaticValues(staticWIP, "WIPOUTSTANDING");

    return (
        // <StaticWIPContext.Provider value={{staticWIP: staticWIP, setStaticWIP: setStaticWIP, staticWIPvalue: staticWIPvalue}}>
        <div className="content-area">
            {/* <StaticMetric id="StaticWIP" query={static_query} objKey='wipRespStatic' header={titleHead}/> */}
            <StaticMetricView label="WIP" data={staticData} header={titleHead} />
            <OutstandingWIP
                // query={unpaid_query}
                type={type}
                filter={filter}
                header="Responsible"
                onChange={setStaticWIP}
            />
        </div>
        // </ StaticWIPContext.Provider>
    );
}

export function RespWIP() {
    const user = useSelector((state) => state.user);
    const { STAFFINDEX, RESPONSIBLE_AR } = user;
    const apiRoot = useSelector(state => state.globals.apiRoot)

    document.title = `Magnify | Client Responsible WIP`;

    if (RESPONSIBLE_AR === 1) {
        const static_query = `${apiRoot}/api/wip/static/responsible/${STAFFINDEX}`;
        const unpaid_query = `${apiRoot}/api/wip/outstanding/responsible/${STAFFINDEX}`;
        // const static_query = `${apiRoot}/api/ar/static/responsible/27`
        // const unpaid_query = `${apiRoot}/api/ar/unpaid/responsible/27`

        return WIPfragment(STAFFINDEX, "My WIP", "responsible");
    } else {
        return <div>Sorry, you don't have access to this report</div>;
    }
}

export function DeptWIP() {
    const user = useSelector((state) => state.user);
    const { DEPT_WIP, DEPARTMENT } = user;

    document.title = `Magnify | ${DEPARTMENT} Department WIP`;

    if (DEPT_WIP === 1) {
        return WIPfragment(DEPARTMENT, `${DEPARTMENT} WIP`, "department");
    } else {
        return <div>Sorry, you don't have access to this report</div>;
    }
}

export function TeamWIP() {
    const user = useSelector((state) => state.user);

    const { TEAM_WIP, HOMEROOM } = user;

    document.title = `Magnify | ${HOMEROOM} Team WIP`;

    if (TEAM_WIP === 1) {
        return WIPfragment(HOMEROOM, `Homeroom WIP`, "team");
    } else {
        return <div>Sorry, you don't have access to this report</div>;
    }
}

export function MyResponsibleWIP() {
    const user = useSelector(state => state.user)
    const [clientList, setClientList] = useState([])
    const [clientPartnerList, setClientPartnerList] = useState([])
    const [clientManagerList, setClientManagerList] = useState([])
    const [jobList, setJobList] = useState([])
    const [jobPartnerList, setJobPartnerList] = useState([])
    const [jobManagerList, setJobManagerList] = useState([])

    const respData = useLiveQuery(async () => {
        await db.WIP.orderBy("CLIENT").uniqueKeys()
            .then(data => {setClientList(data)})
            
        await db.WIP.orderBy("CLIENT_MANAGER").uniqueKeys()
            .then(data => {setClientManagerList(data)})
            
        await db.WIP.orderBy("CLIENT_PARTNER").uniqueKeys()
            .then(data => {setClientPartnerList(data)})

        await db.WIP.orderBy("JOB_NAME").uniqueKeys()
            .then(data => {setJobList(data)})

        await db.WIP.orderBy("JOB_PARTNER").uniqueKeys()
            .then(data => {setJobPartnerList(data)})

        await db.WIP.orderBy("JOB_MANAGER").uniqueKeys()
            .then(data => {setJobManagerList(data)})

        const all_wip = await db.WIP.toArray()
        const job_wip = all_wip.filter(wip => wip.JOB_PARTNER === user.EMPLOYEE || wip.JOB_MANAGER === user.EMPLOYEE)
        const client_wip = all_wip.filter(wip => wip.CLIENT_PARTNER === user.EMPLOYEE || wip.CLIENT_MANAGER === user.EMPLOYEE)

        return { all_wip: all_wip, client_wip: client_wip, job_wip: job_wip }

    })

    const lists = {
        CLIENT: clientList,
        CLIENT_PARTNER: clientPartnerList,
        CLIENT_MANAGER: clientManagerList,
        JOB_NAME: jobList,
        JOB_PARTNER: jobPartnerList,
        JOB_MANAGER: jobManagerList,
        OUTSTANDING_WIP: null
    }

    if (respData && respData.hasOwnProperty('all_wip') && respData.hasOwnProperty('client_wip') && respData.hasOwnProperty('job_wip')) {
        return (
            <section>
                <DoubleWIP left_data={respData.client_wip} left_header={"My Client Responsible WIP"} right_data={respData.job_wip} right_header={'My Job Responsible WIP'} label={"My Total Responsible"} data={respData.all_wip}/>
                <section className="data-view-table">
                    <DataViewTable
                        data={respData.all_wip}
                        columnList={lists}
                        scrollHeight="45vh"
                        onChange={() => true}
                    />
                </section>
            </section>

        )
    } else {
        return (
            <GridLoader />
        )
    }
}
