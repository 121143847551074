import { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const USDollar = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }).format(value);
};

export function PriceGuideDialog({ show, closeCallback, priceData }) {
  return (
    <Dialog
      header={`Current Pricing Guidelines`}
      visible={show}
      onHide={closeCallback}
      onMaskClick={closeCallback}
      className="mx-width-pane"
    >
      <PriceGuide priceData={priceData} />
    </Dialog>
  );
}

export function PriceGuide({ priceData }) {
  const [pricingData, setPricingData] = useState({});
  const [officeList, setOfficeList] = useState([]);
  const [jobList, setJobList] = useState([]);

  const CreateJobRow = ({ job, offices, data }) => {
    const createJobCell = (office, index) => {
      const jobRow = data[office].filter((row) => row.JOB_TYPE === job)[0];
      return (
        <div key={index} className="col value-cell">
          {jobRow.MINIMUM ? (
            <div className="upper-cell">{USDollar(jobRow.MINIMUM)}</div>
          ) : (
            ""
          )}
          {jobRow.UPPER_AVG_RANGE ? (
            <div>
              {USDollar(jobRow.MINIMUM)} - {USDollar(jobRow.UPPER_AVG_RANGE)}
            </div>
          ) : jobRow.INFO ? (
            <div className="cell-info">{jobRow.INFO}</div>
          ) : (
            ""
          )}
        </div>
      );
    };

    const jobInfo = data[officeList[0]].filter(
      (item) => item.JOB_TYPE === job
    )[0].JOB_INFO;

    return (
      <div className="price-table-row grid nested-grid">
        <div className="col-4 pt-border-right job-text grid">
          <div className="col-7 job-text-cell align-content-center">
            {job}
            {jobInfo ? <div className="job-info-text">({jobInfo})</div> : ""}
          </div>
          <div className="col-5 row-legend job-text-cell flex flex-column">
            <div className="range-text">Minimum</div>
            <div className="range-text">Typical Range</div>
          </div>
        </div>
        <div className="col-8 price-row-cell grid nested-grid">
          {officeList.map((office, index) => createJobCell(office, index))}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const orderGuide = [
      "Review",
      "Compilation With Notes",
      "Compilation Without Notes",
      "Business Tax Return",
      "Additional State Tax Return",
      "Individual Tax Return",
      "EBP Audit",
      "Non-Profit Audit",
      "Estate, Trust, and Gift Services",
    ];

    if (priceData) {
      const offices = [...new Set(priceData.map((item) => item.OFFICE))];
      const jobs = [...new Set(priceData.map((job) => job.JOB_TYPE))];

      orderGuide.forEach((job, index) => {
        const jobIndex = jobs.indexOf(job);
        jobs.splice(index, jobIndex, job);
      });

      setOfficeList(offices);
      setJobList(jobs);

      let rows = {};
      offices.forEach((office) => {
        rows[office] = [];
      });
      priceData.forEach((row) => {
        row.index = orderGuide[row.JOB_TYPE];
        rows[row.OFFICE].push(row);
      });
      setPricingData(rows);
    }
  }, [setPricingData, priceData]);

  return (
    <div id="price-table">
      <div id="price-table-header" className="grid">
        <div className="col-4">STANDARD PRICING GUIDELINES</div>
        {officeList.map((office) => (
          <div className="col" key={office}>
            {office}
          </div>
        ))}
      </div>
      {jobList.map((job) => (
        <CreateJobRow
          key={job}
          job={job}
          offices={officeList}
          data={pricingData}
        />
      ))}
    </div>
  );
}
