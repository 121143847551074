import { useRef, useEffect, useMemo } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../utils/DataRefresher";
import { Controller, useFieldArray } from "react-hook-form";
import { ServiceCard } from "./ServiceCard";
import "primeicons/primeicons.css";
import { Panel } from "primereact/panel";
import { MultiSelect } from "primereact/multiselect";

// import { RenderCounter } from "./UtilityComponets";

export default function Services({
  panelClasses,
  panelState,
  onExpand,
  clientType,
  formSetValue,
  lists,
  control,
  getValues,
  watch,
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "services",
  });

  // RenderCounter("Services");

  const serviceTypes = useLiveQuery(async () => {
    let typeData = null;
    if (clientType === "IND") {
      typeData = await db.SERVICES.where("SERVPERSON")
        .equalsIgnoreCase("true")
        .sortBy("SERVNAME");
    } else if (clientType === "ORG") {
      typeData = await db.SERVICES.where("SERVORGANISATION")
        .equalsIgnoreCase("true")
        .sortBy("SERVNAME");
    }

    return typeData;
  }, [clientType]);

  const ref = useRef(null);
  useEffect(() => {
    if (panelState === true) {
      ref.current.expand();
    } else if (panelState === false) {
      ref.current.collapse();
    }
  }, [panelState]);

  const mapServices = useMemo(() => (item, index) => {
    return (
      <ServiceCard
        key={item.id}
        control={control}
        field={item}
        index={index}
        lists={lists}
        remove={remove}
        formSetValue={formSetValue}
        getValues={getValues}
        watch={watch}
      />
    )
  }, [control, lists, remove, watch, getValues, formSetValue])

  let serviceCards = fields.map(mapServices)

  const arrayChange = (newArray, curArray) => {
    if (!curArray) {
      append(newArray);
    } else if (newArray.length > curArray.length) {
      append(newArray.slice(-1));
    } else if (newArray.length < curArray.length) {
      const newIds = newArray.map((item) => item.id);
      curArray.forEach((item, index) => {
        if (!newIds.includes(item.id)) {
          remove(index);
        }
      });
    }
  };

  const selectedService = watch("ServiceSelection");
  return (
    <Panel
      header={<span className="ncto-panel-title">Services and Jobs</span>}
      pt={{ content: { className: panelClasses } }}
      className="justify-content-center"
      ref={ref}
      toggleable
      onExpand={onExpand}
    >
      <div
        id="contact-search"
        className="w-full px-1 align-self-end flex flex-wrap justify-content-center"
      >
        <h6 className="w-full text-center">
          <label>Select Services</label>
        </h6>
        {serviceTypes && (
          <Controller
            name="ServiceSelection"
            control={control}
            defaultValue={[]}
            rules={{ required: "Contact First Name Required." }}
            render={({ field }) => (
              <MultiSelect
                id={field.name}
                placeholder="Services To Add To Client"
                value={selectedService}
                onChange={(e) => {
                  field.onChange(e.value);
                  arrayChange(e.value, selectedService);
                }}
                options={serviceTypes}
                optionLabel="SERVNAME"
                className="w-10"
                display="chip"
              />
            )}
          />
        )}
      </div>
      <section className="flex flex-wrap w-full justify-content-center">
        {serviceCards}
      </section>
    </Panel>
  );
}
